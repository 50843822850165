<template>
  <div id="aboutContent">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center text-info about mb-0">
          <h2>
            <strong>Yardrainage</strong>
            <p>Maintenance and Services</p>
          </h2>
          <p>
            One Call Does It All, If Water Runs Through It We Fix It. Call The
            Best and Flush The Rest
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center text-info about mb-5"></div>
        <div class="col-md-6">
          <h4><strong>Overview</strong></h4>
          <p>
            <strong>YAR Drainage Maintenance Services</strong> is located at EL
            RICH LAND COMPOUND, National Highway Soro Soro Karsada Batangas
            City. We are specialized to give solutions for drain problems in
            both commercial and industrial companies. Together with our team
            experts and and Engineering Department, YAR assured to give an
            excellent performance for both big and small companies. The services
            we provide are building and maintenance such as Engineering and
            Facilities, Motor Pool and House Keeping Department. YAR offers a
            wide range of services, from General Cleaning, De clogging, Exhaust
            cleaning, Re piping, Re-tiling, Online pipe repair, Descaling,
            Chimney Cleaning and AHU Cleaning. Including but not limited in
            Water Treatment , Waste Water Treatment and Drain Treatment.
          </p>
          <p>
            This business organized type is single proprietorship with 30
            employees and counting. Our Goal is to create a service based
            company whose number one goal is exceeding customers expectations,
            To increase our number of clients through are services and To
            develop a sustainable business through profitable contracts and
            services.
          </p>
        </div>
        <div class="col-md-6">
          <h4><strong>Vision</strong></h4>
          <p>
            To become a leading services provider to our customer no matter how
            big or small. Quality with commitment is our Promise to them
          </p>
          <h4><strong>Mission</strong></h4>
          <ul>
            <li>To have a safe and sustainable business</li>
            <li>To provide an excellence service</li>
            <li>To be the first choice of every client</li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "About",
  components: {
    Footer,
  },
};
</script>

<style>
</style>