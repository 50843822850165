<template>
  <div>
    <div class="row">
        <div class="col-md-12 text-center text-info about mb-">
          <h2>
            <strong>Yardrainage</strong>
            <p>Maintenance and Services</p>
          </h2>
          <p>
            One Call Does It All, If Water Runs Through It We Fix It. Call The
            Best and Flush The Rest
          </p>
        </div>
      </div>
    <div class="row">
      <div class="col-md-12 text-center mb-3 mt-4 text-info">
          <!-- <h2><strong>Projects</strong></h2> -->
        </div>
      <div class="col-md-4 mt-5 mb-5" v-for="(client, index) in clients" :key="index">
        <div class="card border-0 shadow cardClient" style="width: 18rem">
          <img :src="client.image" class="card-img-top client" alt="loading" />
          <div class="card-body">
            <h4 class="text-center">
              <h5>{{ client.company }}</h5>
              <h6>{{ client.desc }}</h6>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Client",
  data() {
    return {
      clients: [
        {
          company: "Epson Philippines",
          desc: "Cleaning of Roofing",
          image: require("../assets/images/project1.jpg")
        },
        {
          company: "Teletech Lipa",
          desc: "Retiling and Urinal",
          image: require("../assets/images/project2.jpg")
        },
        {
          company: "Shakeys",
          desc: "Repiping",
          image: require("../assets/images/project3.jpg")
        },
        {
          company: "Mang Inasal",
          desc: "Store Renovation",
          image: require("../assets/images/project4.jpg")
        },
        {
          company: "Sohbi Kohgie",
          desc: "Disinfection",
          image: require("../assets/images/project5.jpg")
        },
        {
          company: "Precision",
          desc: "Retiling and Urinal",
          image: require("../assets/images/project6.jpg")
        }
      ],
    };
  },
};
</script>

<style>
</style>