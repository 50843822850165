<template>
<div>
  <div class="container">
    <Clients/>
  </div>
  <Footer/>
</div>
</template>

<script>
import Clients from '../components/Clients.vue'
import Footer from '../components/Footer.vue'
export default {
  name: "project",
  components:{
    Clients,
    Footer
  },
  data() {
    return {
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          href: "#",
        },
      ]
    };
  },
};
</script>

<style>
</style>