<template>
  <footer class="mt-5 mb-5">
      <hr/>
      <div>
          <div class="row">
              <div class="col text-center">
                  Copyright © YAR DRAINAGE 2020
                  by chardsaid@yahoo.com
              </div>
          </div>
      </div>
  </footer>
</template>

<script>
export default {
    name:'Footer'
}
</script>

<style>

</style>