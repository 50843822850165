<template>
  <div id="aboutContent">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center text-info about mb-0">
          <h2>
            <strong>Yardrainage</strong>
            <p>Maintenance and Services</p>
          </h2>
          <p>
            One Call Does It All, If Water Runs Through It We Fix It. Call The
            Best and Flush The Rest
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center text-info mt-5 mb-5"></div>
        <div class="col-md-4 mb-5">
          <div class="card border-0" style="width: 18rem">
            <img
              src="../assets/images/1.jpg"
              class="card-img-top team"
              alt="Owner"
            />
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="card border-0" style="width: 18rem">
            <img
              src="../assets/images/2.jpg"
              class="card-img-top team"
              alt="Owner"
            />
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="card border-0" style="width: 18rem">
            <img
              src="../assets/images/3.jpg"
              class="card-img-top team"
              alt="Owner"
            />
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-12 text-center mb-5 text-info">
          <h2><strong>Services Offer</strong></h2>
        </div>
        <div class="col-md-6">
          <p>
            <strong>De scaling and Cleaning</strong> - are the process of
            removing limescale from both in or out of tubing such as Boiler,
            Heat Exchanger and Condenser tube.
          </p>
          <p>
            <strong>Pipe Cleaning and Repair</strong> - Plumbing is one of the
            most important role in residential commercial and industrial
            companies. It can suffer from cracks, breaks and even overloading
            system.
          </p>
          <p>
            <strong>Water Treatment </strong> - Water treatment is the process
            of improving quality of water to make it appropriate for specific
            use.
          </p>
          <p>
            <strong>Waste Water Treatment </strong> - Following the DENR
            standards for the new 11 parameters, Our team has undergone training
            for the proper process of WWTP, augmentation of live bacteria if
            necessary and problem solving on the effluent issues.
          </p>
          <p>
            <strong> Exhaust Cleaning </strong> - Exhaust cleaning is the
            process of removing grease that has accumulated inside the ducts,
            hoods, fans and vents. Left uncleaned, exhaust system eventually
            accumulate enough grease to become extreme fire hazard.
          </p>
          <p>
            <strong>Tank Cleaning </strong> - Is the process of removing
            hydrocarbon vapors, liquid or residues.
          </p>
        </div>
        <div class="col-md-6">
          <p>
            <strong>YAR Drainage Maintenance Services</strong> is located at EL
            RICH LAND COMPOUND, National Highway Soro Soro Karsada Batangas
            City. We are specialized to give solutions for drain problems in
            both commercial and industrial companies. Together with our team
            experts and and Engineering Department, YAR assured to give an
            excellent performance for both big and small companies. The services
            we provide are building and maintenance such as Engineering and
            Facilities, Motor Pool and House Keeping Department. YAR offers a
            wide range of services, from General Cleaning, De clogging, Exhaust
            cleaning, Re piping, Re-tiling, Online pipe repair, Descaling,
            Chimney Cleaning and AHU Cleaning. Including but not limited in
            Water Treatment , Waste Water Treatment and Drain Treatment.
          </p>
          <p>
            This business organized type is single proprietorship with 30
            employees and counting. Our Goal is to create a service based
            company whose number one goal is exceeding customers expectations,
            To increase our number of clients through are services and To
            develop a sustainable business through profitable contracts and
            services.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "About",
  components: {
    Footer,
  },
};
</script>

<style>
</style>