<template>
  <div id="contactContent">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center text-info contact mb-5">
          <h2>
            <strong>Yardrainage</strong>
            <p>Maintenance and Services</p>
          </h2>
          <p>
            One Call Does It All, If Water Runs Through It We Fix It. Call The
            Best and Flush The Rest
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <img src="../assets/images/contacts.jpg" />
          <h6>043 300 00300</h6>
          <h6>SORO SORO KARSADA BATANGAS CITY</h6>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Contact",
  components: {
    Footer,
  },
};
</script>

<style>
</style>